@tailwind base;
@tailwind components;
@tailwind utilities;

/* special style for bullets in carousel */
.directBtn[aria-current]:not([aria-current="false"]) {
    border-color: #646464!important;
    background: #646464;
  }       
  .directBtn[aria-current]:not([aria-current="true"]) {
    border-color: #c8c8c8!important;
  }             

  .directBtnWhite[aria-current]:not([aria-current="false"]) {
    border-color: #FFFFFF!important;
    background: #FFFFFF;
  }       
  .directBtnWhite[aria-current]:not([aria-current="true"]) {
    border-color: #FFFFFF!important;
  }     

  @layer components {
    .ICHcontent p, .ICHadress p {
        @apply mb-6 text-[0.9375rem] tracking-[0.08rem] last:mb-0;
    }
    .ICHadress p {
        @apply leading-[1.875rem];
    }
    .ICHadress h2, .ICHcontent h2 {
        @apply mb-6 text-[0.9375rem] tracking-[0.08rem] uppercase font-bold ;
    }
    .ICHcontent a, .ICHadress a {
        @apply underline;
    }

    .locationList:nth-child(even) .locationListImg {
        @apply pr-0 md:pr-4
    }
    .locationList:nth-child(even) .locationListContent {
        @apply pl-0 md:pl-4
    }

    .locationList:nth-child(odd) .locationListImg {
        @apply pl-0 order-1 md:order-2 md:pl-4
    }
    .locationList:nth-child(odd) .locationListContent {
        @apply pr-0 order-2 md:order-1 md:pr-4
    }
    .locationList:nth-child(odd) .locationListBtn {
        @apply text-left md:text-right	
    }

    .directorsDetail p, .directorsDetail p {
        @apply mb-4 sm:mb-6 md:mb-8 last:mb-0
    }

    .locationChoose.active img {
        @apply opacity-50 brightness-50
    }
    .galLeft img {
        @apply ml-auto
    }
    .galRight img {
        @apply mr-auto
    }

    
}

  .gradientHP_top {
    background: rgb(189,198,172);
    background: linear-gradient(0deg, rgba(189,198,172,1) 0%, rgba(120,154,149,1) 62%);
  }
  .gradientHP_bottom {
    background: rgb(212,215,191);
    background: linear-gradient(0deg, rgba(235,215,200,1) 0%, rgba(240,234,185,1) 51%, rgba(212,215,191,1) 100%);
  }
  .gradient_full {
    background: rgb(189,198,172);
    background: linear-gradient(0deg, rgba(235,215,200,1) 0%, rgba(189,198,172,1) 39%, rgba(120,154,149,1) 62%);
  }
  .mulitlinePoint {
      display: -webkit-box !important;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      white-space: normal;
  }

@keyframes loopCity {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-396px);
    }
  }
  @keyframes loopCitySM {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-283px);
    }
  }

  @keyframes para {
	from {
		transform: translateY(30px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes paraL {
	from {
		transform: translateX(30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes paraR {
	from {
		transform: translateX(-30px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
.paraAnim {
	animation: para 1s ease-in-out 0s 1 forwards;
}

.paraAnimR {
	animation: paraR 1s ease-in-out 0s 1 forwards;
}

.paraAnim-5 {
	opacity: 0;
	animation: para 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-5L {
	opacity: 0;
	animation: paraL 1s ease-in-out 0.5s 1 forwards;
}